<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">배너 {{ typeform.id !== '' ? '수정' : '등록' }}</h4>
          <p class="card-title-desc">
            신규 배너를 {{ typeform.id !== '' ? '수정' : '등록' }}합니다.
          </p>
          <form action="#" @submit.prevent="typeForm">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>배너위치</label>
                  <select v-model="typeform.type" class="form-control">
                    <option value="main" selected>main</option>
                    <option value="dashboard">dashboard</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>배너이름</label>
                  <input
                    v-model="typeform.name"
                    type="text"
                    class="form-control"
                    placeholder="배너 이름을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.name.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.name.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>뱃지명</label>
                  <input
                    v-model="typeform.badge"
                    type="text"
                    class="form-control"
                    placeholder="뱃지 이름을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.badge.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.badge.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.badge.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>정렬</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    placeholder="정렬 번호를 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>공개여부</label>
                  <select v-model="typeform.openYn" class="form-control">
                    <option value="Y" selected>공개</option>
                    <option value="N">비공개</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>
                    이미지
                    <a
                      v-if="typeform.thumb !== ''"
                      :href="`https://img.sucoding.kr/banner/${typeform.thumb}`"
                      target="_blank"
                      >(확인)
                    </a>
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    ref="file1"
                    autocomplete="off"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                    @change="fileSelect"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>버튼명</label>
                  <input
                    v-model="typeform.btnName"
                    type="text"
                    class="form-control"
                    placeholder="버튼 이름을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.btnName.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.btnName.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.btnName.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>링크</label>
                  <input
                    v-model="typeform.link"
                    type="text"
                    class="form-control"
                    placeholder="클릭 링크를 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.link.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.link.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.link.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>링크 새창</label>
                  <select v-model="typeform.linkTargetYn" class="form-control">
                    <option value="Y" selected>새창</option>
                    <option value="N">현재창</option>
                  </select>
                  <div
                    v-if="typesubmit && $v.typeform.linkTargetYn.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.linkTargetYn.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>뱃지 클래스</label>
                  <input
                    v-model="typeform.badgeClassName"
                    type="text"
                    class="form-control"
                    placeholder="뱃지 클래스명을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.badgeClassName.$error
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.typeform.badgeClassName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.badgeClassName.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>버튼 클래스</label>
                  <input
                    v-model="typeform.designClassName"
                    type="text"
                    class="form-control"
                    placeholder="디자인 클래스명을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.designClassName.$error
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.typeform.designClassName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.designClassName.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>메인 타이틀</label>
                  <div v-if="typesubmit && $v.typeform.mainTitle.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.mainTitle.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                  <textarea v-model="typeform.mainTitle" class="form-control"></textarea>
                  <!-- <CkeditorNuxtBasic v-model="typeform.mainTitle" :config="config" /> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>서브 타이틀</label>
                  <div v-if="typesubmit && $v.typeform.subTitle.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.subTitle.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                  <textarea v-model="typeform.subTitle" class="form-control"></textarea>
                  <!-- <CkeditorNuxtBasic v-model="typeform.subTitle" :config="config" /> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <div>
                    <button type="submit" class="btn btn-primary me-1">
                      {{ typeform.id !== '' ? '수정' : '등록' }}
                    </button>
                    <button
                      type="reset"
                      class="btn btn-secondary m-l-5 ml-1"
                      @click="$router.push('/banner')"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
// import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
export default Vue.extend({
  components: {
    // CkeditorNuxtBasic
  },
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        id: '',
        type: '',
        name: '',
        badge: '',
        mainTitle: '',
        subTitle: '',
        btnName: '',
        thumb: '',
        link: '',
        linkTargetYn: 'N',
        designClassName: '',
        openYn: 'N',
        orderNum: ''
      },
      config: {
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        },
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        alignment: {
          options: ['left', 'right']
        }
      },
      courseGroupData: [],
      submit: false,
      typesubmit: false
    };
  },
  mounted() {
    if (Object.keys(this.pageData).length > 0) {
      this.typeform.id = this.pageData.idx;
      this.typeform.type = this.pageData.type;
      this.typeform.name = this.pageData.name;
      this.typeform.badge = this.pageData.badgeTitle;
      this.typeform.link = this.pageData.linkSrc;
      this.typeform.linkTargetYn = this.pageData.linkTarget;
      this.typeform.btnName = this.pageData.buttonName;
      this.typeform.designClassName = this.pageData.className;
      this.typeform.badgeClassName = this.pageData.badgeClassName;
      this.typeform.thumb = this.pageData.imgName;
      this.typeform.openYn = this.pageData.openYn;
      this.typeform.orderNum = this.pageData.orderNum;
      setTimeout(() => {
        this.typeform.mainTitle = this.pageData.title;
        this.typeform.subTitle = this.pageData.desc;
      }, 100);
    }
  },
  validations: {
    typeform: {
      name: {required},
      badge: {required},
      type: {required},
      mainTitle: {required},
      subTitle: {required},
      link: {required},
      linkTargetYn: {required},
      btnName: {required},
      designClassName: {required},
      openYn: {required},
      orderNum: {required, numeric}
    }
  },
  methods: {
    fileSelect() {
      this.typeform.file1 = this.$refs.file1.files[0];
    },
    async typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('banner/bannerAdd', {
            id: this.typeform.id,
            name: this.typeform.name,
            type: this.typeform.type,
            badge: this.typeform.badge,
            mainTitle: this.typeform.mainTitle,
            subTitle: this.typeform.subTitle,
            link: this.typeform.link,
            linkTargetYn: this.typeform.linkTargetYn,
            btnName: this.typeform.btnName,
            badgeClassName: this.typeform.badgeClassName,
            designClassName: this.typeform.designClassName,
            orderNum: this.typeform.orderNum,
            openYn: this.typeform.openYn,
            imgFile: this.typeform.file1
          })
          .then(() => {
            if (this.typeform.id !== '') {
              alert('배너가 수정되었습니다.');
            } else {
              alert('배너가 등록되었습니다.');
              this.$router.push('/banner');
            }
          });
      } else {
        console.log(this.$v.$invalid);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .ck-editor__editable {
  height: 150px !important;
  min-height: 150px !important;
}
</style>
